
  import {shuffleArray} from '@/helper/ArrayHelper';

  export default {
    name: 'TeaseredArticles',
    filters: {
      truncate(string, value) {
        return string.length > value
          ? string.substring(0, value) + '…'
          : string;
      },
    },
    props: {
      contentScope: {
        type: String,
        required: false,
        default: 'promotionbasis_candidate',
      },
    },
    data() {
      return {
        selectedItem: {},
        teaseredArticles: [],
        organizationScope: process.env.SCOPE_ORGANIZATION,
      };
    },
    mounted() {
      // Query for teasered articles
      this.$cachedStrapi
        .find(
          'articles',
          {
            scope_contains: this.contentScope,
            article_category_null: false,
            teasered: true,
          },
          'teasered_articles_' + this.contentScope
        )
        .then((teaseredArticles) => {
          this.teaseredArticles = shuffleArray(teaseredArticles).slice(0, 5);
        });
    },
  };
